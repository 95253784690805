import * as React from "react";
import type { SVGProps } from "react";
const Icon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    fill="none"
    viewBox="0 0 84 84"
    {...props}
  >
    <g fill={props.color}>
      <path d="m42 14.086 33.183 6.923 1.634-7.831L42 5.914 7.183 13.178l1.634 7.83zM38 28h8v32h-8zM60 28h8v32h-8zM16 28h8v32h-8zM74.841 68H9.16l-5 5v3H79.84v-3z" />
    </g>
  </svg>
);
export default Icon;
