import React from "react";
import Link, { LinkProps } from "next/link";
import { ReactComponentLike } from "prop-types";
import Colors from "../ui-kit/colors";
// eslint-disable-next-line no-restricted-imports
import { Trans as TransComponent } from "next-i18next";
import { getOrg } from "utils/site";

type LinkTProps = LinkProps & { children?: string[] };

export const LinkT = (props: LinkTProps) => {
  const { children, ...rest } = props;
  if (!children) {
    return null;
  }
  return (
    <Link {...rest} legacyBehavior>
      {children[0]}
    </Link>
  );
};

type EmailTProps = { children?: string; color?: string };

export const EmailT = ({ children, color }: EmailTProps) => {
  return (
    <a style={{ color: color || Colors.blue300 }} href={`mailto:${children}`}>
      {children}
    </a>
  );
};

// eslint-disable-next-line react/display-name
export const transHOC = (Component: ReactComponentLike) => (props: any) => {
  const { children, ...rest } = props;
  if (!children) {
    return null;
  }
  return <Component {...rest}>{children[0]}</Component>;
};

export const Trans = ({
  i18nKey,
  defaults,
  tOptions = {},
  values = {},
  components = {},
}: {
  i18nKey: string;
  defaults?: string;
  tOptions?: Record<string, string | number>;
  values?: Record<string, string | number | null | undefined>;
  components?:
    | readonly React.ReactElement[]
    | { readonly [tagName: string]: React.ReactElement };
}) => {
  return (
    <TransComponent
      i18nKey={i18nKey}
      defaults={defaults}
      values={values}
      tOptions={{ ...tOptions, context: getOrg() }}
      components={components}
    />
  );
};
