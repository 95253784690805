import * as React from "react";
import type { SVGProps } from "react";
const Icon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    fill="none"
    viewBox="0 0 84 84"
    {...props}
  >
    <path
      fill={props.color}
      d="M26 38V26c0-8.822 7.178-16 16-16s16 7.178 16 16l.01 1 5 5h3L66 26C66 12.767 55.234 2 42 2S18 12.767 18 26v12h-6v44h60V38zm38 36H20V46h44z"
    />
  </svg>
);
export default Icon;
