import * as React from "react";
import type { SVGProps } from "react";
const Icon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    fill="none"
    viewBox="0 0 84 84"
    {...props}
  >
    <g fill={props.color}>
      <path d="M38 56h8v8h-8z" />
      <path d="M55.657 2H28.343L2 28.343v27.314L28.343 82h27.314L82 55.657V28.343zM74 52.343 52.343 74H31.657L10 52.343V31.657L31.657 10h20.686L74 31.657z" />
      <path d="M38 20v30h3l5-5V20z" />
    </g>
  </svg>
);
export default Icon;
