import * as React from "react";
import type { SVGProps } from "react";
const Icon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    fill="none"
    viewBox="0 0 84 84"
    {...props}
  >
    <g fill={props.color}>
      <path d="M24.258 63.55v-7.307L8.886 40.23l-5.772 5.54L22.18 65.629zM30.086 73.865l50.879-56.18-5.93-5.37L24.53 68.077z" />
    </g>
  </svg>
);
export default Icon;
