import Script from "next/script";
import Head from "next/head";
import { getTagManagerId, getUserCentricId, isOrg } from "../../utils/site";
import React from "react";
import { useRouter } from "next/router";

const ConsentAndTagManager = ({
  includeHotJar,
}: {
  includeHotJar?: boolean;
}) => (
  <>
    <Script
      type="text/javascript"
      id="gtmInit"
      dangerouslySetInnerHTML={{
        __html: `(function(w, d, s, l, i) {
          w[l] = w[l] || [];
          w[l].push({
              'gtm.start': new Date().getTime(),
              event: 'gtm.js'
          });
          var f = d.getElementsByTagName(s)[0],
              j = d.createElement(s),
              dl = l != 'dataLayer' ? '&l=' + l : '';
          j.async = true;
          j.src =
              'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
          f.parentNode.insertBefore(j, f);
      })(window, document, 'script', 'dataLayer', '${getTagManagerId()}');`,
      }}
    />

    <link rel="preconnect" href="https://www.googletagmanager.com" />
    {!isOrg("swe") ? (
      <Script
        id="TrustpilotScript"
        type="text/plain"
        data-usercentrics="Trustpilot"
        src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
      />
    ) : null}
    {includeHotJar ? (
      <Script
        strategy="afterInteractive"
        id="HotjarScript"
        type="text/plain"
        data-usercentrics="Hotjar"
        dangerouslySetInnerHTML={{
          __html: `
              (function(h,o,t,j,a,r){
                  h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                  h._hjSettings={hjid:2506644,hjsv:6};
                  a=o.getElementsByTagName('head')[0];
                  r=o.createElement('script');r.async=1;
                  r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                  a.appendChild(r);
              })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
              `,
        }}
      />
    ) : null}

    {/* Google Tag Manager (noscript) */}
    <noscript>
      <iframe
        src={`https://www.googletagmanager.com/ns.html?id=${getTagManagerId()}`}
        height="0"
        width="0"
        style={{ display: "none", visibility: "hidden" }}
      ></iframe>
    </noscript>
    {/* End Google Tag Manager (noscript) */}
  </>
);

export default ConsentAndTagManager;
