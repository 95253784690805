import Intercom from "@intercom/messenger-js-sdk";
import { useRouter } from "next/router";
import { getOrg } from "../utils/site";

const APP_ID = "nouqc8mh";

const useIntercom = ({
  hideDefaultLauncher = false,
}: { hideDefaultLauncher?: boolean } = {}) => {
  const { locale } = useRouter();

  Intercom({
    app_id: APP_ID,
    hide_default_launcher: hideDefaultLauncher,
    language_override: locale,
    org: getOrg(),
  });
};

export default useIntercom;
