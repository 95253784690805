// This is generated by `yarn generate-icons`.
import { default as bag } from './components/bag'
import { default as barcode } from './components/barcode'
import { default as calendar } from './components/calendar'
import { default as cat } from './components/cat'
import { default as checkmarkbadgefill } from './components/checkmark-badge-fill'
import { default as checkmarkcircle } from './components/checkmark-circle'
import { default as checkmark } from './components/checkmark'
import { default as chevrondown } from './components/chevron-down'
import { default as chevronleft } from './components/chevron-left'
import { default as chevronright } from './components/chevron-right'
import { default as chevronup } from './components/chevron-up'
import { default as clap } from './components/clap'
import { default as discountbadge } from './components/discount-badge'
import { default as document } from './components/document'
import { default as dog } from './components/dog'
import { default as email } from './components/email'
import { default as errorcircle } from './components/error-circle'
import { default as firstaid } from './components/first-aid'
import { default as globe } from './components/globe'
import { default as graduationcap } from './components/graduation-cap'
import { default as headset } from './components/headset'
import { default as healthhands } from './components/health-hands'
import { default as informationcirclefill } from './components/information-circle-fill'
import { default as informationcircle } from './components/information-circle'
import { default as lightning } from './components/lightning'
import { default as locationmarker } from './components/location-marker'
import { default as location } from './components/location'
import { default as medicalscalpel } from './components/medical-scalpel'
import { default as medication } from './components/medication'
import { default as money } from './components/money'
import { default as paw } from './components/paw'
import { default as phone } from './components/phone'
import { default as points } from './components/points'
import { default as scale } from './components/scale'
import { default as scissors } from './components/scissors'
import { default as starcircle } from './components/star-circle'
import { default as star } from './components/star'
import { default as stethoscope } from './components/stethoscope'
import { default as syringe } from './components/syringe'
import { default as teeth } from './components/teeth'
import { default as throphy } from './components/throphy'
import { default as timecircle } from './components/time-circle'
import { default as trustlybank } from './components/trustly-bank'
import { default as trustlycheckmark } from './components/trustly-checkmark'
import { default as trustlyerror } from './components/trustly-error'
import { default as trustlysecure } from './components/trustly-secure'
import { default as vitamins } from './components/vitamins'
    
export const glyphs = {
  "bag": bag,
  "barcode": barcode,
  "calendar": calendar,
  "cat": cat,
  "checkmark-badge-fill": checkmarkbadgefill,
  "checkmark-circle": checkmarkcircle,
  "checkmark": checkmark,
  "chevron-down": chevrondown,
  "chevron-left": chevronleft,
  "chevron-right": chevronright,
  "chevron-up": chevronup,
  "clap": clap,
  "discount-badge": discountbadge,
  "document": document,
  "dog": dog,
  "email": email,
  "error-circle": errorcircle,
  "first-aid": firstaid,
  "globe": globe,
  "graduation-cap": graduationcap,
  "headset": headset,
  "health-hands": healthhands,
  "information-circle-fill": informationcirclefill,
  "information-circle": informationcircle,
  "lightning": lightning,
  "location-marker": locationmarker,
  "location": location,
  "medical-scalpel": medicalscalpel,
  "medication": medication,
  "money": money,
  "paw": paw,
  "phone": phone,
  "points": points,
  "scale": scale,
  "scissors": scissors,
  "star-circle": starcircle,
  "star": star,
  "stethoscope": stethoscope,
  "syringe": syringe,
  "teeth": teeth,
  "throphy": throphy,
  "time-circle": timecircle,
  "trustly-bank": trustlybank,
  "trustly-checkmark": trustlycheckmark,
  "trustly-error": trustlyerror,
  "trustly-secure": trustlysecure,
  "vitamins": vitamins,
}