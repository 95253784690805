import React, { FC, ReactNode, useMemo } from "react";
import Image from "next/image";
import { useRouter } from "next/router";
import { useWebTranslation } from "../../translations";
import styles from "./LanguageSelector.module.css";
import { LanguagesMap } from "../../constants/LangaugesMap";
import { getDomain } from "utils/site";
import { sites } from "config/site";
import { LanguageGroup, LanguageOption } from "./types";
import Colors from "ui-kit/colors";
import { Icon } from "ui-kit";
import { useSelect, UseSelectSelectedItemChange } from "downshift";
import { Typography } from "ui-kit/Typography";

export const LanguageSelector = () => {
  const { i18n } = useWebTranslation();
  const router = useRouter();

  const changeLanguage = ({
    selectedItem,
  }: UseSelectSelectedItemChange<LanguageOption>) => {
    const { locale, domain } = selectedItem;

    if (domain === getDomain() && locale !== i18n?.language) {
      router.push("/", "/", { locale });

      return;
    }

    if (domain !== getDomain()) {
      window.location.href = `https://${domain}${locale === "en" ? "/en" : ""}`;
      return;
    }
  };

  const languageOptions = useMemo(
    () =>
      Object.values(sites).reduce((options, config) => {
        return [
          ...options,
          {
            label: LanguagesMap[config.defaultLocale].countryName as string,
            options: config.locales.map((locale) => {
              return {
                id: `${locale}-${config.domain}`,
                name: `${
                  LanguagesMap[locale === "en" ? "en-GB" : locale].name
                }`,
                locale,
                flag: config.defaultLocale,
                domain: config.domain,
                countryName:
                  LanguagesMap[config.defaultLocale].countryName || "",
              };
            }),
          },
        ];
      }, [] as Array<LanguageGroup>),
    []
  );

  const items = Object.values(sites).flatMap((config) => {
    return config.locales.map((locale) => {
      return {
        id: `${locale}-${config.domain}`,
        name: `${LanguagesMap[locale === "en" ? "en-GB" : locale].name}`,
        locale: locale as string,
        flag: config.defaultLocale as string,
        domain: config.domain,
        countryName: LanguagesMap[config.defaultLocale].countryName || "",
      };
    });
  }, [] as Array<LanguageGroup>);

  const selectedLanguage = useMemo(
    () =>
      items.find(
        (language) =>
          language.domain === getDomain() && language.locale === i18n?.language
      ),
    [items, i18n?.language]
  );

  const {
    isOpen,
    selectedItem,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
  } = useSelect({
    items,
    onSelectedItemChange: changeLanguage,
    defaultSelectedItem: selectedLanguage,
  });

  return (
    <>
      <div style={{ background: "blue" }}></div>
      <div className={styles.wrapper}>
        <ul {...getMenuProps()} className={styles.listContainer}>
          {isOpen && (
            <div className={styles.list}>
              {
                languageOptions.reduce(
                  (result, section, sectionIndex) => {
                    result.sections.push(
                      <div key={sectionIndex}>
                        <Typography
                          variant="body4"
                          color="grey400"
                          style={{ textTransform: "uppercase" }}
                        >
                          {section.label}
                        </Typography>
                        {section.options.map((language, languageIndex) => {
                          const index = result.itemIndex++;
                          return (
                            <li
                              key={languageIndex}
                              className={styles.listItem}
                              {...getItemProps({
                                item: language,
                                index,
                              })}
                              style={{
                                background:
                                  highlightedIndex === index
                                    ? Colors.grey200
                                    : selectedItem?.id === language.id
                                    ? Colors.grey300
                                    : undefined,
                              }}
                            >
                              <Image
                                src={`/images/flags/${language.flag}.svg`}
                                alt=""
                                width="24"
                                height="24"
                              />
                              <Typography variant="body3">
                                <strong>{language.name}</strong>
                              </Typography>
                            </li>
                          );
                        })}
                      </div>
                    );

                    return result;
                  },
                  { sections: [] as ReactNode[], itemIndex: 0 }
                ).sections
              }
            </div>
          )}
        </ul>
        <button
          className={styles.toggleButton}
          aria-label="toggle menu"
          {...getToggleButtonProps()}
        >
          <div className={styles.valueContainer}>
            <Image
              src={`/images/flags/${selectedLanguage?.flag}.svg`}
              alt=""
              width="24"
              height="24"
              style={{ marginInlineEnd: 8 }}
            />
            <Typography variant="body2">
              <strong>
                {`${selectedLanguage?.countryName} · ${selectedLanguage?.name}`}
              </strong>
            </Typography>
          </div>
          {
            <div className={styles.icon}>
              <Icon
                name={isOpen ? "chevron-up" : "chevron-down"}
                size="S"
                color={Colors.grey300}
              />
            </div>
          }
        </button>
      </div>
    </>
  );
};
