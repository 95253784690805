import { Org, getOrg, Domain } from "../utils/site";

const EIGHT_WEEKS = 56;
const SIX_WEEKS = 42;

enum ResolvedDomains {
  de = "de.lassie.co",
  co = "www.lassie.co",
  fr = "fr.lassie.co",
}

export type Campaign = "puppy" | "kitten" | "multipet";

type CurrencyCode = "SEK" | "EUR";
type CurrencySymbol = "kr" | "€";
type CountryCode = "DE" | "FR" | "SE";
export type Locale = "sv" | "de" | "en" | "fr";
export type DateFormat = "yyyy-MM-dd" | "dd.MM.yyyy" | "dd/MM/yyyy";
export enum SelectablePaymentMethodEnum {
  Stripe = "STRIPE",
  Trustly = "TRUSTLY",
  TrustlyMdd = "TRUSTLY_MDD",
  TrustlyMddManual = "TRUSTLY_MDD_MANUAL",
}

type SiteConfig = {
  appStoreUrl: string;
  currency: {
    code: CurrencyCode; // ISO currency code
    symbol: CurrencySymbol;
  };
  contactDetails: {
    email: string;
    meetingBookingUrl: string;
  };
  dateFormat: DateFormat;
  defaultLocale: Locale;
  campaigns?: Partial<Record<Campaign, string>>;
  domain: Domain;
  resolvedDomain: ResolvedDomains;
  locales: Array<Locale>;
  countryCode: CountryCode;
  tagManagerId: {
    production: string;
    development: string;
  };
  userCentricId: string;
  insurance: {
    startOffsetInDays: number;
    minimumAgeInDays: number;
    copays: Array<number>;
    deductibles: Array<number>;
    preventiveCareAddOnLimits?: Array<number>;
    limits?: Array<number>;
    defaultLimit: number;
    defaultDeducible: number;
    defaultCopay: number;
  };
  payment: {
    selectablePaymentMethods: Array<SelectablePaymentMethodEnum>;
  };
  authentication: "email" | "bankid";
  /**
   * Setting a program ID will activate the tracking in production
   */
  leadAllianceProgramId?: string;
};

export const sites: Record<Org, SiteConfig> = {
  swe: {
    appStoreUrl: "https://apps.apple.com/se/app/lassie/id1533808157",
    currency: {
      code: "SEK",
      symbol: "kr",
    },
    contactDetails: {
      email: "hej@lassie.co",
      meetingBookingUrl:
        "https://calendly.com/lassie-swe/samtal?hide_gdpr_banner=1",
    },
    countryCode: "SE",
    dateFormat: "yyyy-MM-dd",
    defaultLocale: "sv",
    campaigns: {
      puppy: "VALP40",
      kitten: "KATTUNGE40",
    },
    domain: "lassie.co",
    resolvedDomain: ResolvedDomains.co,
    locales: ["sv", "en"],
    tagManagerId: {
      production: "GTM-TV3V99V",
      development: "GTM-55W2GKD",
    },
    userCentricId: "GYz0QbblVCjJYX",
    insurance: {
      minimumAgeInDays: SIX_WEEKS,
      startOffsetInDays: 0,
      copays: [0.15, 0.25],
      deductibles: [0, 150000, 350000],
      defaultCopay: 0.25,
      defaultDeducible: 1500_00,
      defaultLimit: 60_000_00,
    },
    payment: {
      selectablePaymentMethods: [
        SelectablePaymentMethodEnum.Trustly, //SelectablePaymentMethodEnum.TrustlyMdd,
        SelectablePaymentMethodEnum.Stripe,
      ],
    },
    authentication: "bankid",
  },
  ger: {
    appStoreUrl: "https://apps.apple.com/de/app/lassie/id1533808157",
    currency: {
      code: "EUR",
      symbol: "€",
    },
    contactDetails: {
      email: "kontakt@lassie.de",
      meetingBookingUrl:
        "https://calendly.com/d/ckfv-vjk-fvc?hide_gdpr_banner=1",
    },
    countryCode: "DE",
    dateFormat: "dd.MM.yyyy",
    defaultLocale: "de",
    domain: "lassie.de",
    resolvedDomain: ResolvedDomains.de,
    locales: ["de", "en"],
    tagManagerId: {
      production: "GTM-59KVKPV",
      development: "GTM-55W2GKD",
    },
    userCentricId: "jLt3bzUO3",
    insurance: {
      minimumAgeInDays: EIGHT_WEEKS,
      startOffsetInDays: 1,
      copays: [0, 0.2],
      deductibles: [],
      defaultCopay: 0.2,
      defaultDeducible: 0,
      defaultLimit: 1000_00,
    },
    payment: {
      selectablePaymentMethods: [
        SelectablePaymentMethodEnum.TrustlyMddManual,
        SelectablePaymentMethodEnum.Stripe,
      ],
    },
    authentication: "email",
    leadAllianceProgramId: "2381",
  },
  fra: {
    appStoreUrl: "https://apps.apple.com/fr/app/lassie/id1533808157",
    contactDetails: {
      email: "contact@lassie.fr",
      meetingBookingUrl: "https://calendly.com/d/cpnv-gdq-9zx",
    },
    currency: {
      code: "EUR",
      symbol: "€",
    },
    countryCode: "FR",
    dateFormat: "dd/MM/yyyy",
    defaultLocale: "fr",
    domain: "lassie.fr",
    resolvedDomain: ResolvedDomains.fr,
    locales: ["fr", "en"],
    payment: {
      selectablePaymentMethods: [
        SelectablePaymentMethodEnum.Stripe,
        SelectablePaymentMethodEnum.TrustlyMddManual,
      ],
    },
    tagManagerId: {
      production: "GTM-WGM4PMP5",
      development: "GTM-55W2GKD",
    },
    userCentricId: "_h3DiHVTcVErVH",
    insurance: {
      minimumAgeInDays: EIGHT_WEEKS,
      startOffsetInDays: 0,
      copays: [0.4, 0.2, 0],
      deductibles: [],
      preventiveCareAddOnLimits: [100_00, 160_00, 220_00],
      limits: [1000_00, 2000_00, 3000_00],
      defaultCopay: 0.2,
      defaultDeducible: 0,
      defaultLimit: 2000_00,
    },
    campaigns: {
      puppy: "CHIOT40",
      kitten: "CHATON40",
      multipet: "PLUSIEURSANIMAUX",
    },
    authentication: "email",
    leadAllianceProgramId: "2567",
  },
};

export const getSiteConfig = () => sites[getOrg()];
