import React, { ReactElement } from "react";
import Link from "next/link";
import Image from "next/image";
import styles from "./Footer.module.css";
import {
  prepurchaseInfoInsurance,
  prepurchaseInfoLassie,
} from "../components-shared/insuranceData";
import { packages } from "./InsurancePackages";
import { useWebTranslation } from "../translations";
import OldIcon from "../ui-kit/Icon";
import { Org, getContactEmail, getOrg, isOrg } from "../utils/site";
import { Typography } from "../ui-kit/Typography";
import { LanguageSelector } from "./LanguageSelector/LanguageSelector";
import classNames from "classnames";
import { Glyph, Icon } from "@/ui-kit";
import { TuvBadges } from "./TuvBadges";

const EmailLink = () => {
  const email = getContactEmail();

  return (
    <a href={`mailto:${email}`} className={styles.link}>
      <strong>{email}</strong>
    </a>
  );
};

const LinkWithIcon = ({
  children,
  icon,
}: {
  children: ReactElement | ReactElement[];
  icon: Glyph;
}) => {
  return (
    <div className={styles.linkWithIcon}>
      <Icon name={icon} size="M" className={styles.linkIcon} />
      {children}
    </div>
  );
};

const SwedishColumns = () => {
  const { t } = useWebTranslation();

  return (
    <>
      <section className={classNames(styles.footerInfo, styles.column)}>
        <Typography variant="body4">{t("footer.info")}</Typography>
      </section>
      <section className={styles.column}>
        <h3 className={styles.header}>
          <Typography variant="body2">
            <strong>{t("footer.important_info")}</strong>
          </Typography>
        </h3>
        <ul>
          <li>
            <Link href="/villkor" className={styles.link}>
              <strong>{t("footer.insurance_terms")}</strong>
            </Link>
          </li>
          <li>
            <Link href={prepurchaseInfoInsurance} className={styles.link}>
              <strong> {t("footer.purchase_info")}</strong>
            </Link>
          </li>
          <li>
            <Link href={prepurchaseInfoLassie} className={styles.link}>
              <strong> {t("footer.lassie_info")}</strong>
            </Link>
          </li>
          <li>
            <Link href="/integritetspolicy" className={styles.link}>
              <strong>{t("footer.personal_data_policy")}</strong>
            </Link>
          </li>
          <li>
            <Link href={t("footer.cookie_policy.href")} className={styles.link}>
              <strong> {t("footer.cookie_policy.text")}</strong>
            </Link>
          </li>
          <li>
            <a
              onClick={() => {
                // @ts-ignore
                window.UC_UI?.showSecondLayer();
              }}
              className={styles.link}
            >
              <strong>{t("global.privacy_settings")}</strong>
            </a>
          </li>
          <li>
            <Link href={packages().dog[0].ipid} className={styles.link}>
              <strong>{t("footer.fact_sheet_mini")}</strong>
            </Link>
          </li>
          <li>
            <Link href={packages().dog[1].ipid} className={styles.link}>
              <strong>{t("footer.fact_sheet_between")}</strong>
            </Link>
          </li>
          <li>
            <Link href={packages().dog[2].ipid} className={styles.link}>
              <strong> {t("footer.fact_sheet_big")}</strong>
            </Link>
          </li>
        </ul>
      </section>
      <section className={styles.column}>
        <h3 className={styles.header}>
          <Typography variant="body2">
            <strong>Lassie</strong>
          </Typography>
        </h3>
        <ul>
          <li>
            <Link href="/kontakta-oss" className={styles.link}>
              <strong> {t("navigation.contact_us")}</strong>
            </Link>
          </li>
          <li>
            <Link href="/om-oss" className={styles.link}>
              <strong> {t("navigation.about_us")}</strong>
            </Link>
          </li>
        </ul>
      </section>
      <section className={styles.column}>
        <h3 className={styles.header}>
          <Typography variant="body2">
            <strong>{t("footer.contact")}</strong>
          </Typography>
        </h3>
        <ul>
          <li>
            <a
              href="https://www.facebook.com/Lassie.Sweden"
              target="blank"
              className={styles.link}
            >
              <OldIcon name="facebook" size={18} color="inherit" />
              <strong>Facebook</strong>
            </a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/lassie_se/"
              target="blank"
              className={styles.link}
            >
              <OldIcon name="instagram" size={18} color="inherit" />
              <strong>Instagram</strong>
            </a>
          </li>
          <li>
            <a
              href="https://www.youtube.com/channel/UCBS4ArF2BlR2TixpopcOrnQ"
              target="blank"
              className={styles.link}
            >
              <OldIcon name="youtube" size={18} color="inherit" />
              <strong>Youtube</strong>
            </a>
          </li>
          <li>
            <a
              href="https://www.linkedin.com/company/lassieco/"
              target="blank"
              className={styles.link}
            >
              <OldIcon name="linkedin" size={18} color="inherit" />
              <strong>LinkedIn</strong>
            </a>
          </li>
          <li>
            <br />
            <address className={styles.address}>
              <LinkWithIcon icon="email">
                <a href={`mailto:${getContactEmail()}`}>
                  <strong>{getContactEmail()}</strong>
                </a>
              </LinkWithIcon>
              <br />
              <LinkWithIcon icon="phone">
                <a href="tel://0101839818" style={{ whiteSpace: "nowrap" }}>
                  <strong>010-183 98 18</strong>
                </a>
              </LinkWithIcon>
              <br />
              <LinkWithIcon icon="location">
                <div>
                  <strong>Surbrunnsgatan 14</strong>
                  <br />
                  <strong>114 27 Stockholm</strong>
                </div>
              </LinkWithIcon>
            </address>
          </li>
        </ul>
      </section>
    </>
  );
};

const GermanColumns = () => {
  const { t } = useWebTranslation();

  return (
    <>
      <section className={styles.column}>
        <h3 className={styles.header}>
          <Typography variant="body2">
            <strong>{t("footer.important_info")}</strong>
          </Typography>
        </h3>
        <ul>
          <li>
            <Link href={t("footer.imprint.href")} className={styles.link}>
              <strong>{t("footer.imprint.text")}</strong>
            </Link>
          </li>
          <li>
            <Link
              href={t("footer.privacy_policy.href")}
              className={styles.link}
            >
              <strong>{t("footer.privacy_policy.text")}</strong>
            </Link>
          </li>
          <li>
            <Link href={t("footer.cookie_policy.href")} className={styles.link}>
              <strong>{t("footer.cookie_policy.text")}</strong>
            </Link>
          </li>
          <li>
            <a
              className={styles.link}
              onClick={() => {
                // @ts-ignore
                window.UC_UI?.showSecondLayer();
              }}
            >
              <strong> {t("global.privacy_settings")}</strong>
            </a>
          </li>

          <li>
            <Link
              href={t("footer.pre-purchase_information.href")}
              className={styles.link}
            >
              <strong>{t("footer.pre-purchase_information.text")}</strong>
            </Link>
          </li>
          <li>
            <Link href={t("footer.tac.href")} className={styles.link}>
              <strong>{t("footer.tac.text")}</strong>
            </Link>
          </li>

          <li>
            <Link
              href={t("footer.ipid_health.href")}
              target="_blank"
              className={styles.link}
            >
              <strong>{t("footer.ipid_health.text")}</strong>
            </Link>
          </li>
          <li>
            <Link
              href={t("footer.ipid_op.href")}
              target="_blank"
              className={styles.link}
            >
              <strong>{t("footer.ipid_op.text")}</strong>
            </Link>
          </li>
          <Link
            href={t("footer.ipid_liability.href")}
            target="_blank"
            className={styles.link}
          >
            <strong>{t("footer.ipid_liability.text")}</strong>
          </Link>
        </ul>
      </section>
      <section className={styles.column}>
        <h3 className={styles.header}>
          <Typography variant="body2">
            <strong>Lassie</strong>
          </Typography>
        </h3>
        <ul>
          <li>
            <Link href={t("footer.contact_us.href")} className={styles.link}>
              <strong>{t("navigation.contact_us")}</strong>
            </Link>
          </li>
          <li>
            <Link href={t("footer.affiliate.href")} className={styles.link}>
              <strong>{t("footer.affiliate.text")}</strong>
            </Link>
          </li>
        </ul>
      </section>
      <section className={styles.column}>
        <h3 className={styles.header}>
          <Typography variant="body2">
            <strong>{t("footer.contact")}</strong>
          </Typography>
        </h3>
        <ul>
          <li>
            <a
              href="https://www.facebook.com/lassie.germany"
              target="blank"
              className={styles.link}
            >
              <OldIcon name="facebook" size={18} color="inherit" />
              <strong>Facebook</strong>
            </a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/lassie_de/"
              target="blank"
              className={styles.link}
            >
              <OldIcon name="instagram" size={18} color="inherit" />
              <strong>Instagram</strong>
            </a>
          </li>
          <li>
            <a
              href="https://www.youtube.com/channel/UCBS4ArF2BlR2TixpopcOrnQ"
              target="blank"
              className={styles.link}
            >
              <OldIcon name="youtube" size={18} color="inherit" />
              <strong>Youtube</strong>
            </a>
          </li>
          <li>
            <a
              href="https://www.linkedin.com/company/lassieco/"
              target="blank"
              className={styles.link}
            >
              <OldIcon name="linkedin" size={18} color="inherit" />
              <strong>LinkedIn</strong>
            </a>
          </li>
          <li>
            <address className={styles.address}>
              <EmailLink />
              <br />
              <strong>Lassie AB Zweigniederlassung</strong>
              <br />
              <strong>c/o Smart Host</strong>
              <br />
              <strong>Am Kupfergraben 6a</strong>
              <br />
              <strong>10117 Berlin</strong>
            </address>
          </li>
        </ul>
      </section>
    </>
  );
};

const FrenchColumns = () => {
  const { t } = useWebTranslation();

  return (
    <>
      <section className={styles.column}>
        <h3 className={styles.header}>
          <Typography variant="body2">
            <strong>{t("footer.important_info")}</strong>
          </Typography>
        </h3>
        <ul>
          <li>
            <Link
              href="https://docs.lassie.co/fra/latest/ipid.pdf"
              className={styles.link}
              target="_blank"
            >
              <strong>{t("insurance_documents.ipid")}</strong>
            </Link>
          </li>

          <li>
            <Link
              href="https://docs.lassie.co/fra/latest/conditions-generales-de-lassurance-sante-animale-lassie.pdf"
              className={styles.link}
              target="_blank"
            >
              <strong>{t("insurance_documents.terms_and_conditions")}</strong>
            </Link>
          </li>
          <li>
            <Link
              href="https://docs.lassie.co/fra/latest/mentions-legales.pdf"
              className={styles.link}
              target="_blank"
            >
              <strong>{t("insurance_documents.legal")}</strong>
            </Link>
          </li>
          <li>
            <Link
              href="https://docs.lassie.co/fra/latest/cgu.pdf"
              className={styles.link}
              target="_blank"
            >
              <strong>{t("insurance_documents.cgu")}</strong>
            </Link>
          </li>
          <li>
            <Link
              href={t("footer.privacy_policy.href")}
              className={styles.link}
            >
              <strong>{t("footer.privacy_policy.text")}</strong>
            </Link>
          </li>
          <li>
            <Link href={t("footer.cookie_policy.href")} className={styles.link}>
              <strong>{t("footer.cookie_policy.text")}</strong>
            </Link>
          </li>
          <li>
            <a
              className={styles.link}
              onClick={() => {
                // @ts-ignore
                window.UC_UI?.showSecondLayer();
              }}
            >
              <strong> {t("global.privacy_settings")}</strong>
            </a>
          </li>
          <li>
            <Link
              href="https://docs.lassie.co/fra/feuille-de-soins.pdf"
              className={styles.link}
              target="_blank"
            >
              <strong>{t("insurance_documents.claims")}</strong>
            </Link>
          </li>
        </ul>
      </section>
      <section className={styles.column}>
        <h3 className={styles.header}>
          <Typography variant="body2">
            <strong>{t("footer.contact")}</strong>
          </Typography>
        </h3>
        <ul>
          <li>
            <Link href={t("footer.contact_us.href")} className={styles.link}>
              <strong>{t("navigation.contact_us")}</strong>
            </Link>
          </li>
          <li>
            <Link href={t("footer.affiliate.href")} className={styles.link}>
              <strong>{t("footer.affiliate.text")}</strong>
            </Link>
          </li>
          <li>
            <EmailLink />
          </li>
          <li>
            <address className={styles.address}>
              <strong>Lassie AB</strong>
              <br />
              <strong>9 rue des Colonnes</strong>
              <br />
              <strong>75002 Paris</strong>
            </address>
          </li>
        </ul>
      </section>
      <section className={styles.column}>
        <h3 className={styles.header}>
          <Typography variant="body2">
            <strong>{t("footer.follow_us")}</strong>
          </Typography>
        </h3>
        <ul>
          <li>
            <a
              href="https://www.facebook.com/people/Lassie-France/61558443117226/"
              target="blank"
              className={styles.link}
            >
              <OldIcon name="facebook" size={18} color="inherit" />
              <strong>Facebook</strong>
            </a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/lassie_fr/"
              target="blank"
              className={styles.link}
            >
              <OldIcon name="instagram" size={18} color="inherit" />
              <strong>Instagram</strong>
            </a>
          </li>
          <li>
            <a
              href="https://www.linkedin.com/company/lassieco/"
              target="blank"
              className={styles.link}
            >
              <OldIcon name="linkedin" size={18} color="inherit" />
              <strong>LinkedIn</strong>
            </a>
          </li>
        </ul>
      </section>
    </>
  );
};

const columns: Record<Org, () => ReactElement> = {
  swe: () => <SwedishColumns />,
  ger: () => <GermanColumns />,
  fra: () => <FrenchColumns />,
};

export default function Footer({
  siteLanguages,
}: {
  siteLanguages: (string | null)[] | null | undefined;
}): ReactElement {
  const { t } = useWebTranslation();

  return (
    <footer className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.topRow}>
          <Image
            width={104}
            height={25}
            src="/images/logo-with-name.svg"
            className={styles.logo}
            alt="Lassie"
            loading="lazy"
          />
          <div className={styles.languageSelector}>
            <LanguageSelector />
          </div>
        </div>
        <div
          className={classNames(
            styles.grid,
            isOrg("swe") ? "" : styles.withoutInfo
          )}
        >
          {columns[getOrg()]()}
        </div>
        <div
          className={styles.bottomRow}
          style={{ marginTop: isOrg("ger") ? -40 : 0 }}
        >
          <div className={styles.copyright}>{t("footer.rights")}</div>
          {isOrg("ger") ? (
            <TuvBadges withLinks style={{ flexDirection: "row" }} />
          ) : null}
        </div>
      </div>
    </footer>
  );
}
