/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum DiscountType {
  Age = "Age",
  Marketing = "Marketing",
  MultiPet = "MultiPet",
  Referral = "Referral",
}

export enum PaymentMethodEnum {
  STRIPE = "STRIPE",
  TRUSTLY = "TRUSTLY",
  TRUSTLY_MDD = "TRUSTLY_MDD",
}

export enum PaymentTransactionStatus {
  Disputed = "Disputed",
  Failed = "Failed",
  Incomplete = "Incomplete",
  Pending = "Pending",
  PendingSucceeded = "PendingSucceeded",
  Succeded = "Succeded",
}

export enum PayoutMethodEnum {
  ATLAR = "ATLAR",
  PAYPAL = "PAYPAL",
  TRUSTLY = "TRUSTLY",
  TRUSTLY_MDD = "TRUSTLY_MDD",
}

export enum ProductEnum {
  cat = "cat",
  dog = "dog",
  liability = "liability",
}

export enum SpeciesEnum {
  cat = "cat",
  dog = "dog",
}

export interface ArticleFilter {
  author?: cfAuthorNestedFilter | null;
  breeds?: cfBreedNestedFilter | null;
  category?: cfTopicNestedFilter | null;
  topics?: cfTopicNestedFilter | null;
  video?: cfVideoNestedFilter | null;
  species?: cfSpeciesNestedFilter | null;
  relatedArticles?: cfArticleNestedFilter | null;
  sys?: SysFilter | null;
  contentfulMetadata?: ContentfulMetadataFilter | null;
  author_exists?: boolean | null;
  title_exists?: boolean | null;
  title?: string | null;
  title_not?: string | null;
  title_in?: (string | null)[] | null;
  title_not_in?: (string | null)[] | null;
  title_contains?: string | null;
  title_not_contains?: string | null;
  metadataTitle_exists?: boolean | null;
  metadataTitle?: string | null;
  metadataTitle_not?: string | null;
  metadataTitle_in?: (string | null)[] | null;
  metadataTitle_not_in?: (string | null)[] | null;
  metadataTitle_contains?: string | null;
  metadataTitle_not_contains?: string | null;
  seoDescription_exists?: boolean | null;
  seoDescription?: string | null;
  seoDescription_not?: string | null;
  seoDescription_in?: (string | null)[] | null;
  seoDescription_not_in?: (string | null)[] | null;
  seoDescription_contains?: string | null;
  seoDescription_not_contains?: string | null;
  uri_exists?: boolean | null;
  uri?: string | null;
  uri_not?: string | null;
  uri_in?: (string | null)[] | null;
  uri_not_in?: (string | null)[] | null;
  uri_contains?: string | null;
  uri_not_contains?: string | null;
  shortText_exists?: boolean | null;
  shortText?: string | null;
  shortText_not?: string | null;
  shortText_in?: (string | null)[] | null;
  shortText_not_in?: (string | null)[] | null;
  shortText_contains?: string | null;
  shortText_not_contains?: string | null;
  content_exists?: boolean | null;
  content_contains?: string | null;
  content_not_contains?: string | null;
  breedsCollection_exists?: boolean | null;
  mainImage_exists?: boolean | null;
  readTime_exists?: boolean | null;
  readTime?: number | null;
  readTime_not?: number | null;
  readTime_in?: (number | null)[] | null;
  readTime_not_in?: (number | null)[] | null;
  readTime_gt?: number | null;
  readTime_gte?: number | null;
  readTime_lt?: number | null;
  readTime_lte?: number | null;
  fromBirthWeek_exists?: boolean | null;
  fromBirthWeek?: number | null;
  fromBirthWeek_not?: number | null;
  fromBirthWeek_in?: (number | null)[] | null;
  fromBirthWeek_not_in?: (number | null)[] | null;
  fromBirthWeek_gt?: number | null;
  fromBirthWeek_gte?: number | null;
  fromBirthWeek_lt?: number | null;
  fromBirthWeek_lte?: number | null;
  toBirthWeek_exists?: boolean | null;
  toBirthWeek?: number | null;
  toBirthWeek_not?: number | null;
  toBirthWeek_in?: (number | null)[] | null;
  toBirthWeek_not_in?: (number | null)[] | null;
  toBirthWeek_gt?: number | null;
  toBirthWeek_gte?: number | null;
  toBirthWeek_lt?: number | null;
  toBirthWeek_lte?: number | null;
  category_exists?: boolean | null;
  topicsCollection_exists?: boolean | null;
  video_exists?: boolean | null;
  species_exists?: boolean | null;
  relatedArticlesCollection_exists?: boolean | null;
  org_exists?: boolean | null;
  org_contains_all?: (string | null)[] | null;
  org_contains_some?: (string | null)[] | null;
  org_contains_none?: (string | null)[] | null;
  OR?: (ArticleFilter | null)[] | null;
  AND?: (ArticleFilter | null)[] | null;
}

export interface ChangePaymentMethodInput {
  paymentMethodId: string;
  paymentMethod: PaymentMethodEnum;
}

export interface ChangePayoutMethodInput {
  payoutMethodId?: string | null;
  payoutMethod?: PayoutMethodEnum | null;
}

export interface ChargeInput {
  paymentCandidateId: string;
}

export interface CheckMultiBindInput {
  draftId: string;
}

export interface ClientDraftAddressInput {
  city?: string | null;
  line1?: string | null;
  line2?: string | null;
  zip: string;
}

export interface ClientDraftInput {
  firstName?: string | null;
  lastName?: string | null;
  ssn?: any | null;
  email?: string | null;
  birthdate?: string | null;
  phone?: string | null;
  region?: string | null;
  address?: ClientDraftAddressInput | null;
  allowMarketing?: boolean | null;
}

export interface CompleteSetupPaymentMethodInput {
  paymentMethodSetupId?: string | null;
  paymentMethod?: PaymentMethodEnum | null;
  payoutMethod?: PayoutMethodEnum | null;
}

export interface ContentfulMetadataConceptsDescendantsFilter {
  id_contains_all?: (string | null)[] | null;
  id_contains_some?: (string | null)[] | null;
  id_contains_none?: (string | null)[] | null;
}

export interface ContentfulMetadataConceptsFilter {
  id_contains_all?: (string | null)[] | null;
  id_contains_some?: (string | null)[] | null;
  id_contains_none?: (string | null)[] | null;
  descendants?: ContentfulMetadataConceptsDescendantsFilter | null;
}

export interface ContentfulMetadataFilter {
  tags_exists?: boolean | null;
  tags?: ContentfulMetadataTagsFilter | null;
  concepts_exists?: boolean | null;
  concepts?: ContentfulMetadataConceptsFilter | null;
}

export interface ContentfulMetadataTagsFilter {
  id_contains_all?: (string | null)[] | null;
  id_contains_some?: (string | null)[] | null;
  id_contains_none?: (string | null)[] | null;
}

export interface FaqItemFilter {
  sys?: SysFilter | null;
  contentfulMetadata?: ContentfulMetadataFilter | null;
  question_exists?: boolean | null;
  question?: string | null;
  question_not?: string | null;
  question_in?: (string | null)[] | null;
  question_not_in?: (string | null)[] | null;
  question_contains?: string | null;
  question_not_contains?: string | null;
  answer_exists?: boolean | null;
  answer_contains?: string | null;
  answer_not_contains?: string | null;
  OR?: (FaqItemFilter | null)[] | null;
  AND?: (FaqItemFilter | null)[] | null;
}

export interface GetBreedsInput {
  limit?: number | null;
  species?: SpeciesEnum | null;
  search?: string | null;
}

export interface InitMultiBindInput {
  draftId: string;
  paymentMethod: PaymentMethodEnum;
  data?: PaymentMethodData | null;
}

export interface IsSignaturePresentInput {
  binary?: string | null;
}

export interface PaymentMethodData {
  type?: string | null;
  iban?: string | null;
  returnUrl?: string | null;
}

export interface PolicyDraftInput {
  _id: string;
  productName: ProductEnum;
  effectiveDate: string;
  billingInterval: number;
  billingDay?: number | null;
  limit: number;
  deductible: number;
  ratingsData: PolicyDraftRatingsDataInput;
  metadata: PolicyDraftMetadataInput;
}

export interface PolicyDraftMetadataInput {
  discount?: string | null;
  healthHistory?: string | null;
  insuranceHistory?: string | null;
  previousInsurance?: string | null;
  cancelPreviousInsurance?: boolean | null;
  customerSignature?: string | null;
  eurobonusMemberId?: string | null;
  lunarMemberId?: string | null;
  compare?: boolean | null;
  chipNumber?: string | null;
}

export interface PolicyDraftRatingsDataInput {
  name: string;
  breed: string;
  birthday: string;
  sex: string;
  lifeLimit?: number | null;
  copay?: number | null;
  breedersAddOn?: boolean | null;
  lifeUtilityAddOn?: string | null;
  access?: string | null;
  clientBirthdate?: string | null;
  liabilityBestAddOn?: boolean | null;
  liabilitySosAddOn?: boolean | null;
  liabilityRentedAddOn?: boolean | null;
  preventiveCareAddOnLimit?: number | null;
}

export interface QuotePreviewInput {
  id: string;
}

export interface RemovePaymentMethodInput {
  paymentMethodId: string;
  paymentMethod: PaymentMethodEnum;
}

export interface ResumeDraftInput {
  _id: string;
}

export interface SetupPaymentMethodInput {
  paymentMethod: PaymentMethodEnum;
  data?: PaymentMethodData | null;
}

export interface SysFilter {
  id_exists?: boolean | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: (string | null)[] | null;
  id_not_in?: (string | null)[] | null;
  id_contains?: string | null;
  id_not_contains?: string | null;
  publishedAt_exists?: boolean | null;
  publishedAt?: any | null;
  publishedAt_not?: any | null;
  publishedAt_in?: (any | null)[] | null;
  publishedAt_not_in?: (any | null)[] | null;
  publishedAt_gt?: any | null;
  publishedAt_gte?: any | null;
  publishedAt_lt?: any | null;
  publishedAt_lte?: any | null;
  firstPublishedAt_exists?: boolean | null;
  firstPublishedAt?: any | null;
  firstPublishedAt_not?: any | null;
  firstPublishedAt_in?: (any | null)[] | null;
  firstPublishedAt_not_in?: (any | null)[] | null;
  firstPublishedAt_gt?: any | null;
  firstPublishedAt_gte?: any | null;
  firstPublishedAt_lt?: any | null;
  firstPublishedAt_lte?: any | null;
  publishedVersion_exists?: boolean | null;
  publishedVersion?: number | null;
  publishedVersion_not?: number | null;
  publishedVersion_in?: (number | null)[] | null;
  publishedVersion_not_in?: (number | null)[] | null;
  publishedVersion_gt?: number | null;
  publishedVersion_gte?: number | null;
  publishedVersion_lt?: number | null;
  publishedVersion_lte?: number | null;
}

export interface TopicFilter {
  species?: cfSpeciesNestedFilter | null;
  sys?: SysFilter | null;
  contentfulMetadata?: ContentfulMetadataFilter | null;
  name_exists?: boolean | null;
  name?: string | null;
  name_not?: string | null;
  name_in?: (string | null)[] | null;
  name_not_in?: (string | null)[] | null;
  name_contains?: string | null;
  name_not_contains?: string | null;
  uri_exists?: boolean | null;
  uri?: string | null;
  uri_not?: string | null;
  uri_in?: (string | null)[] | null;
  uri_not_in?: (string | null)[] | null;
  uri_contains?: string | null;
  uri_not_contains?: string | null;
  color_exists?: boolean | null;
  color?: string | null;
  color_not?: string | null;
  color_in?: (string | null)[] | null;
  color_not_in?: (string | null)[] | null;
  color_contains?: string | null;
  color_not_contains?: string | null;
  image_exists?: boolean | null;
  species_exists?: boolean | null;
  icon_exists?: boolean | null;
  icon?: string | null;
  icon_not?: string | null;
  icon_in?: (string | null)[] | null;
  icon_not_in?: (string | null)[] | null;
  icon_contains?: string | null;
  icon_not_contains?: string | null;
  iconColor_exists?: boolean | null;
  iconColor?: string | null;
  iconColor_not?: string | null;
  iconColor_in?: (string | null)[] | null;
  iconColor_not_in?: (string | null)[] | null;
  iconColor_contains?: string | null;
  iconColor_not_contains?: string | null;
  iconBackground_exists?: boolean | null;
  iconBackground?: string | null;
  iconBackground_not?: string | null;
  iconBackground_in?: (string | null)[] | null;
  iconBackground_not_in?: (string | null)[] | null;
  iconBackground_contains?: string | null;
  iconBackground_not_contains?: string | null;
  OR?: (TopicFilter | null)[] | null;
  AND?: (TopicFilter | null)[] | null;
}

export interface UpsertDraftInput {
  _id?: string | null;
  clientInput?: ClientDraftInput | null;
  policyInputs?: PolicyDraftInput[] | null;
}

export interface cfArticleNestedFilter {
  sys?: SysFilter | null;
  contentfulMetadata?: ContentfulMetadataFilter | null;
  author_exists?: boolean | null;
  title_exists?: boolean | null;
  title?: string | null;
  title_not?: string | null;
  title_in?: (string | null)[] | null;
  title_not_in?: (string | null)[] | null;
  title_contains?: string | null;
  title_not_contains?: string | null;
  metadataTitle_exists?: boolean | null;
  metadataTitle?: string | null;
  metadataTitle_not?: string | null;
  metadataTitle_in?: (string | null)[] | null;
  metadataTitle_not_in?: (string | null)[] | null;
  metadataTitle_contains?: string | null;
  metadataTitle_not_contains?: string | null;
  seoDescription_exists?: boolean | null;
  seoDescription?: string | null;
  seoDescription_not?: string | null;
  seoDescription_in?: (string | null)[] | null;
  seoDescription_not_in?: (string | null)[] | null;
  seoDescription_contains?: string | null;
  seoDescription_not_contains?: string | null;
  uri_exists?: boolean | null;
  uri?: string | null;
  uri_not?: string | null;
  uri_in?: (string | null)[] | null;
  uri_not_in?: (string | null)[] | null;
  uri_contains?: string | null;
  uri_not_contains?: string | null;
  shortText_exists?: boolean | null;
  shortText?: string | null;
  shortText_not?: string | null;
  shortText_in?: (string | null)[] | null;
  shortText_not_in?: (string | null)[] | null;
  shortText_contains?: string | null;
  shortText_not_contains?: string | null;
  content_exists?: boolean | null;
  content_contains?: string | null;
  content_not_contains?: string | null;
  breedsCollection_exists?: boolean | null;
  mainImage_exists?: boolean | null;
  readTime_exists?: boolean | null;
  readTime?: number | null;
  readTime_not?: number | null;
  readTime_in?: (number | null)[] | null;
  readTime_not_in?: (number | null)[] | null;
  readTime_gt?: number | null;
  readTime_gte?: number | null;
  readTime_lt?: number | null;
  readTime_lte?: number | null;
  fromBirthWeek_exists?: boolean | null;
  fromBirthWeek?: number | null;
  fromBirthWeek_not?: number | null;
  fromBirthWeek_in?: (number | null)[] | null;
  fromBirthWeek_not_in?: (number | null)[] | null;
  fromBirthWeek_gt?: number | null;
  fromBirthWeek_gte?: number | null;
  fromBirthWeek_lt?: number | null;
  fromBirthWeek_lte?: number | null;
  toBirthWeek_exists?: boolean | null;
  toBirthWeek?: number | null;
  toBirthWeek_not?: number | null;
  toBirthWeek_in?: (number | null)[] | null;
  toBirthWeek_not_in?: (number | null)[] | null;
  toBirthWeek_gt?: number | null;
  toBirthWeek_gte?: number | null;
  toBirthWeek_lt?: number | null;
  toBirthWeek_lte?: number | null;
  category_exists?: boolean | null;
  topicsCollection_exists?: boolean | null;
  video_exists?: boolean | null;
  species_exists?: boolean | null;
  relatedArticlesCollection_exists?: boolean | null;
  org_exists?: boolean | null;
  org_contains_all?: (string | null)[] | null;
  org_contains_some?: (string | null)[] | null;
  org_contains_none?: (string | null)[] | null;
  OR?: (cfArticleNestedFilter | null)[] | null;
  AND?: (cfArticleNestedFilter | null)[] | null;
}

export interface cfAuthorNestedFilter {
  sys?: SysFilter | null;
  contentfulMetadata?: ContentfulMetadataFilter | null;
  name_exists?: boolean | null;
  name?: string | null;
  name_not?: string | null;
  name_in?: (string | null)[] | null;
  name_not_in?: (string | null)[] | null;
  name_contains?: string | null;
  name_not_contains?: string | null;
  title_exists?: boolean | null;
  title?: string | null;
  title_not?: string | null;
  title_in?: (string | null)[] | null;
  title_not_in?: (string | null)[] | null;
  title_contains?: string | null;
  title_not_contains?: string | null;
  portrait_exists?: boolean | null;
  OR?: (cfAuthorNestedFilter | null)[] | null;
  AND?: (cfAuthorNestedFilter | null)[] | null;
}

export interface cfBreedNestedFilter {
  sys?: SysFilter | null;
  contentfulMetadata?: ContentfulMetadataFilter | null;
  id_exists?: boolean | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: (string | null)[] | null;
  id_not_in?: (string | null)[] | null;
  id_contains?: string | null;
  id_not_contains?: string | null;
  name_exists?: boolean | null;
  name?: string | null;
  name_not?: string | null;
  name_in?: (string | null)[] | null;
  name_not_in?: (string | null)[] | null;
  name_contains?: string | null;
  name_not_contains?: string | null;
  species_exists?: boolean | null;
  lifeAgeGroup_exists?: boolean | null;
  lifeAgeGroup?: number | null;
  lifeAgeGroup_not?: number | null;
  lifeAgeGroup_in?: (number | null)[] | null;
  lifeAgeGroup_not_in?: (number | null)[] | null;
  lifeAgeGroup_gt?: number | null;
  lifeAgeGroup_gte?: number | null;
  lifeAgeGroup_lt?: number | null;
  lifeAgeGroup_lte?: number | null;
  OR?: (cfBreedNestedFilter | null)[] | null;
  AND?: (cfBreedNestedFilter | null)[] | null;
}

export interface cfSpeciesNestedFilter {
  sys?: SysFilter | null;
  contentfulMetadata?: ContentfulMetadataFilter | null;
  name_exists?: boolean | null;
  name?: string | null;
  name_not?: string | null;
  name_in?: (string | null)[] | null;
  name_not_in?: (string | null)[] | null;
  name_contains?: string | null;
  name_not_contains?: string | null;
  code_exists?: boolean | null;
  code?: string | null;
  code_not?: string | null;
  code_in?: (string | null)[] | null;
  code_not_in?: (string | null)[] | null;
  code_contains?: string | null;
  code_not_contains?: string | null;
  OR?: (cfSpeciesNestedFilter | null)[] | null;
  AND?: (cfSpeciesNestedFilter | null)[] | null;
}

export interface cfTopicNestedFilter {
  sys?: SysFilter | null;
  contentfulMetadata?: ContentfulMetadataFilter | null;
  name_exists?: boolean | null;
  name?: string | null;
  name_not?: string | null;
  name_in?: (string | null)[] | null;
  name_not_in?: (string | null)[] | null;
  name_contains?: string | null;
  name_not_contains?: string | null;
  uri_exists?: boolean | null;
  uri?: string | null;
  uri_not?: string | null;
  uri_in?: (string | null)[] | null;
  uri_not_in?: (string | null)[] | null;
  uri_contains?: string | null;
  uri_not_contains?: string | null;
  color_exists?: boolean | null;
  color?: string | null;
  color_not?: string | null;
  color_in?: (string | null)[] | null;
  color_not_in?: (string | null)[] | null;
  color_contains?: string | null;
  color_not_contains?: string | null;
  image_exists?: boolean | null;
  species_exists?: boolean | null;
  icon_exists?: boolean | null;
  icon?: string | null;
  icon_not?: string | null;
  icon_in?: (string | null)[] | null;
  icon_not_in?: (string | null)[] | null;
  icon_contains?: string | null;
  icon_not_contains?: string | null;
  iconColor_exists?: boolean | null;
  iconColor?: string | null;
  iconColor_not?: string | null;
  iconColor_in?: (string | null)[] | null;
  iconColor_not_in?: (string | null)[] | null;
  iconColor_contains?: string | null;
  iconColor_not_contains?: string | null;
  iconBackground_exists?: boolean | null;
  iconBackground?: string | null;
  iconBackground_not?: string | null;
  iconBackground_in?: (string | null)[] | null;
  iconBackground_not_in?: (string | null)[] | null;
  iconBackground_contains?: string | null;
  iconBackground_not_contains?: string | null;
  OR?: (cfTopicNestedFilter | null)[] | null;
  AND?: (cfTopicNestedFilter | null)[] | null;
}

export interface cfVideoNestedFilter {
  sys?: SysFilter | null;
  contentfulMetadata?: ContentfulMetadataFilter | null;
  title_exists?: boolean | null;
  title?: string | null;
  title_not?: string | null;
  title_in?: (string | null)[] | null;
  title_not_in?: (string | null)[] | null;
  title_contains?: string | null;
  title_not_contains?: string | null;
  media_exists?: boolean | null;
  placeholderImage_exists?: boolean | null;
  lengthSeconds_exists?: boolean | null;
  lengthSeconds?: number | null;
  lengthSeconds_not?: number | null;
  lengthSeconds_in?: (number | null)[] | null;
  lengthSeconds_not_in?: (number | null)[] | null;
  lengthSeconds_gt?: number | null;
  lengthSeconds_gte?: number | null;
  lengthSeconds_lt?: number | null;
  lengthSeconds_lte?: number | null;
  mux_exists?: boolean | null;
  OR?: (cfVideoNestedFilter | null)[] | null;
  AND?: (cfVideoNestedFilter | null)[] | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
